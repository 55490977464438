const actions = () => {
  return {
    setAdminUser: {
      handler({ commit }, value) {
        commit('SET_ADMIN_USER', value);
      },
    },
    resetUser: {
      handler({ commit }) {
        commit('RESET_USER');
      },
    },
    setUserRoles: {
      handler({ commit }, value) {
        commit('SET_USER_ROLES', value);
      },
    },
    setUserName: {
      handler({ commit }, value) {
        commit('SET_USER_NAME', value);
      },
    },
  };
};

export default actions;