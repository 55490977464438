import { useGtm } from '@gtm-support/vue2-gtm';

const INTERNAL_DOMAIN = ["@morningstar.com", "@dealx.com"]

function trackEvent(event, category, action, label, value) {
    useGtm().trackEvent({
        event: event, // Event type [default = 'interaction'] (Optional)
        category: category,
        action: action,
        label: label,
        value: value,
    });
}

function isExternalUser(userName) {
  return !INTERNAL_DOMAIN.some((domain) => userName?.includes(domain));
}

export default trackEvent;
export { isExternalUser };