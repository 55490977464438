<template>
  <div class="top-hat-container">
    <mmds-top-hat v-if="topHatData.nav" :data="topHatData" />
  </div>
</template>

<script>
import { MmdsTopHat } from "@mmds/top-hat";
import { mapActions, mapState } from "vuex";

export default {
  components: { MmdsTopHat },
  computed: {
    ...mapState({
      topHatData: (state) => state.tophat.topHatData,
    }),
  },
  mounted() {
    this.getTopHatData();
  },
  methods: {
    ...mapActions({
      getTopHatData: "tophat/getTopHatData",
    }),
  },
};
</script>
<style lang="scss" module>
@import "@mds/constants";
.top-hat-container {
  .mmds-top-hat-drawer,
  .top-hat-compact__drawer {
    z-index: 200;
  }
}
</style>
