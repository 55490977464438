<template>
  <div id="contact">
    <span class="contact-name">{{ name }} - </span>
    <span class="contact-designation">{{ designation }}</span>
    <br />
    <span>
      <a class="contact-email-link" :href="emailLink">{{ emailAddress }}</a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ContactItem',
  props: {
    name: {
      type: String,
      default: '',
      required: true,
    },
    designation: {
      type: String,
      default: '',
      required: true,
    },
    emailAddress: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    emailLink() {
      return 'mailto:' + this.emailAddress;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#contact {
  padding: $mds-space-2-x 0px;
}

.contact-name {
  @include mds-level-6-heading($bold: true);
  color: $mds-color-white;
}

.contact-designation {
  @include mds-level-6-heading($bold: true);
  color: $mds-color-white;
  white-space: nowrap;
}

.contact-email-link {
  @include mds-link($in-table: true, $visited-styles: false, $on-dark: true);
  @include mds-body-text-m();
  color: $mds-color-white;
}
</style>
