<template>
  <div class="mcia-outer-container">
    <div class="mcia-inner-container">
      <mds-layout-grid>
        <mds-row>
          <mds-col class="copyright-column" :cols="12">
            <p>Copyright {{ currentYear }} Morningstar, Inc. All rights reserved.</p>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: 'Footer',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mcia-outer-container {
  min-height: 40px;
  margin-top: $mds-space-half-x;
}

.copyright-column {
  @include mds-body-text-s();
  color: $mds-text-color-primary;
  text-align: center;
}
</style>
