<template>
  <section class="dv-mcia-nav">
    <mds-tabs
      responsive
      class="dv-mcia-nav-menu"
      variation="site-navigation"
      aria-label="main-navigation-menu"
      ref="myTabs"
      :size="tabSize"
      :alignment="tabAlignment"
    >
      <mds-tabs-item
        v-for="menuItem in menuConfig"
        exactPath
        class="dv-mcia-nav-menu-item"
        :icon-right="menuItem.subMenu ? 'caret-down' : ''"
        :key="`menu-item-${menuItem.id}`"
        :id="`menu-item-id-${menuItem.id}`"
        :text="menuItem.label"
        :active="activeTabId == menuItem.id"
        @click.native="handleMenuClick(menuItem)"
      >
      </mds-tabs-item>
      <SubMenu
        :subMenu="currentSubMenu.items"
        :triggeredBy="`menu-item-id-${currentSubMenu.id}`"
        :position="['bottom-center']"
        :visible="visible"
        @onClickTab="handleSubMenuClick"
        @popOverClose="handleCloseMenu"
      />
    </mds-tabs>
  </section>
</template>

<script>
import SubMenu from '@/components/Menu/SubMenu/SubMenu.vue';
import { MdsTabs, MdsTabsItem } from '@mds/tabs';
import menuConfig from '@/data/menu.json';
import { flatMapDeep } from 'lodash';

export default {
  name: 'Menu',
  components: {
    SubMenu,
    MdsTabs,
    MdsTabsItem,
  },
  data() {
    return {
      activeTab: 'Home',
      menuConfig: menuConfig,
      currentSubMenu: {
        items: [],
        triggeredBy: '',
      },
      visible: false,
      tabSize: 'large',
      tabAlignment: 'center',
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  },
  computed: {
    activeTabId() {
      this.menuConfig.forEach((menuItem) => {
        if (menuItem?.subMenu) {
          const allSubmenuItems = this.getAllSubmenuItems(menuItem.subMenu);
          allSubmenuItems?.forEach((item) => {
            if (item.id == this.$route?.name) 
              this.activeTab = menuItem?.id;
          });
        } else if (menuItem?.id == this.$route?.name) {
          this.activeTab = menuItem?.id;
        }
      });
      return this.activeTab;
    },
  },
  methods: {
    async handleMenuClick(menuItem) {
      const routePath = menuItem?.link;
      if (! (menuItem?.elementId && document.getElementById(menuItem?.elementId))) {
        if (this.$route?.path !== routePath && routePath !== undefined && routePath !== '#') {
          await this.$router.push({ path: routePath }).catch();
        }
      }
      //hide or show submenu
      if (menuItem?.subMenu) {
        this.visible = !this.visible;
        this.currentSubMenu = {
          id: menuItem?.id,
          items: menuItem?.subMenu || [],
        };
      } else {
        this.visible = false;
        this.scrollToSection(menuItem);
      }
    },
    handleWindowResize() {
      this.$refs?.myTabs?.responsiveTabs();
      if (window.outerWidth < 600) {
        this.tabSize = 'medium';
        this.tabAlignment = 'left';
      } else {
        this.tabSize = 'large';
        this.tabAlignment = 'center';
      }
    },
    handleSubMenuClick() {
      this.visible = false;
    },
    handleCloseMenu() {
      this.visible = false;
    },
    scrollToSection(menuItem) {
      //scrooll to specific section
      if (menuItem?.elementId) {
        var element = document.getElementById(menuItem?.elementId);
        element.scrollIntoView({ behavior: 'smooth' });
      } else if (menuItem?.link) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    getAllSubmenuItems(menuItem) {
      return flatMapDeep(menuItem, (item) => {
        return [item].concat(this.getAllSubmenuItems(item.subMenu) || []);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dv-mcia-nav {
  @media (min-width: $mds-breakpoint-s) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  overflow-x: auto;
  &-menu {
    border: none;
    &::after {
      display: none;
    }
    ::v-deep {
      ul {
        @media (min-width: $mds-breakpoint-s) {
          justify-content: center;
          padding-left: $mds-space-1-x;
        }
      }
    }
    &-item {
      &_router_link {
        @include mds-level-6-heading();
        color: $mds-text-color-primary;
        text-decoration: none;
      }
      &_label {
        @include mds-level-6-heading();
        color: $mds-text-color-primary;
      }
    }
  }
}
.mds-tabs--centered .mds-tabs__item {
  margin: 0 $mds-space-4-x;
}
</style>