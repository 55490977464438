import axios from 'axios';
import alertEvent from '@/services/alertEvent';
import { redirectToCreditLogin } from "@/userauthentication";
import router from '@/router';

const {
  defaults: { adapter: defaultAdapter },
} = axios;

export { defaultAdapter };

export class Http {
  constructor(baseHeaders, url = null, timeout = undefined, adapter = defaultAdapter) {
    this.clientRequestId = `ct-${new Date().getTime()}`;
    const baseURL = url || process.env.VUE_APP_API_BASE_URL;
    const headers = this.getDefaultHeaders(baseHeaders);
    this.baseURL = baseURL;
    this.headers = headers;

    this.client = axios.create({
      baseURL,
      headers,
      timeout,
      adapter,
    });

    this.defineInterceptors();
  }

  setClient(client) {
    this.client = client;
  }

  callApi(params) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const headers = this.getDefaultHeaders(params.headers);

    const xth = this.client?.request({
      ...params,
      headers,
      cancelToken: source?.token,
      withCredentials: true,
    }) || {};

    xth.abort = () => {
      source?.cancel('cancel');
    };
    return xth;
  }

  /*
    *  @params are the URL parameters to be sent with the request
    *  @config contains request configuration such as responseType, timeout, responseEncoding, maxContentLength, baseURL
    */
  get(url, params = {}, config = { responseType: 'json' }) {
    return this.callApi({
      url,
      params,
      ...config,
      method: 'GET',
    });
  }

  post(url, data, config = { responseType: 'json' }) {
    return this.callApi({
      url,
      data,
      ...config,
      method: 'POST',
    });
  }

  put(url, data, config = { responseType: 'json' }) {
    return this.callApi({
      url,
      data,
      ...config,
      method: 'PUT',
    });
  }

  delete(url, data, config = { responseType: 'json' }) {
    const params = {
      url,
      ...config,
      method: 'DELETE',
    };
    if (data) {
      params.data = data;
    }
    return this.callApi(params);
  }

  getDefaultHeaders(headers) {
    const headersObj = {
      // default to sending JSON
      'Content-Type': 'application/json',
      // default to accepting JSON
      Accept: 'application/json',
      /*
        * Security Headers
        * References:
        * https://content-security-policy.com/
        * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Strict-Transport-Security
        * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control
        * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Content-Type-Options
        * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Frame-Options
        */
      'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
      'Cache-Control': 'no-cache',
      'Content-Security-Policy':
      "default-src 'none'; script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';",
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'sameorigin',
      'Client-Request-Id': this.clientRequestId,
      'Access-Control-Allow-Origin': '*',
      // passed headers will take precedence above defaults
      ...headers,
    };

    return headersObj;
  }

  defineInterceptors() {
    this.client.interceptors.response.use((response) => {
        return response;
      },
      (error) => {
        if (!axios.isCancel(error)) {
          const errorStatusCode = error?.response?.status;
          switch (errorStatusCode) {
            case 401:
              redirectToCreditLogin(new URL(window.location.href));
              break;
            case 403:
              router.push({ name: "AccessDenied" });
              break;
            case 409:
              this.callAlertEvent("Error", error?.response?.data['error']);
              break;
            case 400:
              this.callAlertEvent("Error", "Invalid Input");
              break;
            case 500:
              this.callAlertEvent("Error", "Internal Server Error");
              break;
            default:
              this.callAlertEvent(
                "Error",
                "It looks like something went wrong"
              );
              break;
          }
          return Promise.reject(error);
        }
        return Promise.resolve();
      }
    );
  }

  callAlertEvent = (error, msg) => {
    alertEvent.$emit("alert", { title: error, message: msg });
  }
}

export default new Http();