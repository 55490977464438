<template>
  <div class="mcia-outer-container" id="contact-us">
    <div class="mcia-inner-container">
      <mds-layout-grid class="contact-us">
        <mds-row>
          <mds-col>
            <div class="contact-us-title">
              <span class="contact-us-title-label">{{ title }}</span>
            </div>
          </mds-col>
        </mds-row>
        <mds-row>
          <mds-col :cols="12" :cols-at-s="9">
            <mds-row>
              <mds-col 
                v-for="(contact, index) in contacts" 
                :key="`contact-item-${index}`"
                :cols="12" 
                :cols-at-s="6" 
              >
                <contact-item :name="contact.name" :designation="contact.designation" :emailAddress="contact.emailAddress" />
              </mds-col>
            </mds-row>
          </mds-col>
          <mds-col :cols="12" :cols-at-s="3">
            <mds-row>
              <mds-col v-for="(addressItem, index) in mciaAddress" :key="`address-item-${index}`" :cols="12">
                <div class="contact-us-address">
                  <div class="contact-us-address-title">Address</div>
                  <span class="contact-us-address-details">{{ addressItem.address }}</span>
                </div>
              </mds-col>
            </mds-row>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
    <div class="copyright-column">
      <div class="mcia-inner-container">
        <router-link class="link-text" :to="copyrightLinks.disclaimer">Disclaimer</router-link>|
          <router-link class="link-text" :to="copyrightLinks.termsAndconditions">Terms and Conditions</router-link>|
            <a target="_blank" class="link-text" :href="copyrightLinks.privacyPolicy">Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import ContactItem from '@/components/ContactUs/ContactItem.vue';
import usersData from '@/data/data.json';
import copyrightData from '@/data/constants.json';

export default {
  name: 'ContactUs',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    ContactItem,
  },
  data() {
    return {
      copyrightLinks: copyrightData?.links
    }
  },
  computed: {
    mciaAddress() {
      const result = usersData?.address;
      return result;
    },
    contacts() {
      var result = [];
      usersData?.contacts.forEach((contact) => {
        if (contact?.type.includes(this.$contactType)) {
          result.push(contact);
        }
      });
      return result;
    },
    title() {
      if (this.$contactType === 'cre') {
        return 'Contact us for a custom-built solution tailored to your organization';
      } else {
        return 'Contact us';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mcia-outer-container {
  background-color: #1F55A5;
  min-height: 300px;
  .mcia-inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contact-us {
  padding-top: $mds-space-5-x;
  color: $mds-text-color-primary-on-dark;
  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-title-label {
    @include mds-level-2-heading($bold: true);
    border-bottom: $mds-space-half-x solid;
    padding-bottom: $mds-space-1-x;
    text-align: center;
    margin-bottom: $mds-space-2-x;
  }
}

.contactus-title {
  @include mds-level-3-heading($bold: true);
  color: $mds-color-white;
  text-align: left;
  padding: $mds-space-4-x 0px;
}

.contact-column {
  overflow-wrap: break-word;
}

.contact-us-address {
  padding: $mds-space-2-x 0px;
  &-title {
    @include mds-level-5-heading($bold: true);
    color: $mds-color-white;
  }
  &-details {
    @include mds-body-text-m();
    color: $mds-color-white;
  }
}

.copyright-column {
  @include mds-body-text-s();
  color: $mds-text-color-primary-on-dark;
  border-top: 0.3px solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: $mds-space-2-x;
}

.link-text {
  @include mds-link($on-dark: true, $visited-styles: false, $underline: false);
  padding: 0px $mds-space-1-x;
}
</style>
