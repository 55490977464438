<template>
  <div>
    <mds-card size="small" :label="formattedDate" :title="title">
      <template>
        <div><router-link :to="{ path: 'newsflash/'+ encodeURIComponent(itemid) }" class="link-text">{{title}}</router-link>
        </div>
      </template>
    </mds-card>
  </div>
</template>

<script>
import moment from 'moment';
import MdsCard from '@mds/card';

export default {
  name: 'NewsFlashItem',
  components: {
    MdsCard,
  },
  computed: {
    formattedDate() {
      return moment(this.publishDate).format('MMM DD, YYYY');
    },
  },
  props: {
    itemid: {
      type: String,
      default: '',
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    publishDate: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@mds/constants';
@import '@mds/fonts';
@import '@mds/typography';
@import '@mds/link';

.mds-card {
  border-width: 0px;
  margin-top: $mds-space-1-x;
  width: 100%;
  // height: 118px;
  height: 100%;
  border-radius: 0;
  border-bottom: $mds-border-separator-on-light;
}

.remove-underline {
  text-decoration: none;
}
.mds-card {
  ::v-deep {
    .mds-card__caption {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      @include mds-body-text-m();
      padding-bottom: $mds-space-half-x;
      margin-bottom: $mds-space-1-x !important;
    }
    .mds-card__title {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      padding-bottom: $mds-space-half-x;
      @include mds-body-text-m($bold: true);
    }
    .link-text  {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      @include mds-link($in-table: true);
      @include mds-body-text-m();
      color: #006FBA !important;
    }
  }
}
</style>
