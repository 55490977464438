export const events = {
    event: {
        SIGN_IN: 'sign_in',
        GET_STARTED: "get_started_submit",
        RESEARCH_ARTICLE_DOWNLOAD: "research_article_download",
    },
    category: {
        SIGN_IN: 'Sign In',
        GET_STARTED: "Get Started",
        RESEARCH_ARTICLE_DOWNLOAD: "Research Article Download",
    },
    action: {
        CLICK: 'click'
    },
    label: {
        SIGN_IN: 'Sign In',
        GET_STARTED: "Get Started",
        RESEARCH_ARTICLE_DOWNLOAD: "Research Article Download",
    }
}