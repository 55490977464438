import Http from './api';
import moment from 'moment-timezone';

const encodeData = (data) => {
  return encodeURIComponent(data);
};

const formatNewsFlashList = (data) => {
  return data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      date: moment(item.createdAt).tz("America/New_York").format('MMM DD, YYYY'),
    };
  }).sort((a, b) => {
    return moment(b.date, 'MMM DD, YYYY').diff(moment(a.date, 'MMM DD, YYYY'));
  });
};

export const getNewsFlashList = async (period) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/newsflash/emaillist?period=${period}`;

  return Http.get(url).then((response) => {
    return formatNewsFlashList(response?.data || []);
  });
};

export const getNewsFlashListByCount = async (count) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/newsflash/emaillist?count=${count}`;

  return Http.get(url).then((response) => {
    return formatNewsFlashList(response?.data || []);
  });
};

export const getNewsFlashItem = async (id) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/newsflash/email/${encodeData(id)}`;

  return Http.get(url).then((response) => {
    return formatNewsFlashList(response?.data || []);
  });
};

export const getNewsFlashEmail = async (id) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/newsflash/emailcontent/${encodeData(id)}.html`;
  var emailContent = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "text/html",
      "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
    },
  }).then((response) => response.text());
  return emailContent;
}
