const mutations = {
  SET_ACTIVE_SIGN_IN_LINK(state, payload) {
    state.activeSignInLink = payload;
  },
  SET_SIGN_IN_VISIBILITY(state, payload) {
    state.showSignInButton = payload;
  },
  SET_GET_STARTED_VISIBILITY(state, payload) {
    state.showGetStartedButton = payload;
  },
  SET_MENU_VISIBILITY(state, payload) {
    state.showMenu = payload;
  },
  SET_SELECTED_PRODUCTS(state, payload) {
    state.selectedProducts = payload;
  },
};

export default mutations;
