<template>
  <mds-popover
    v-if="subMenu.length > 0"
    class="sub-menu"
    width="200px"
    :key="`submenu-${triggeredBy}`"
    :position="position"
    :triggeredBy="triggeredBy"
    :visible="visible"
    @mds-popover-dismissed="handleClosePopover"
  >
    <mds-list-group>
      <mds-list-group-item
        v-for="(menuItem, menuIndex) in subMenu"
        :key="menuIndex"
        :id="`sub-menu-${menuItem.id}`"
        @click.native="handleItemClick(menuItem)"
      >
        <span>{{ menuItem.label }}</span> 
        <template v-if="menuItem.subMenu">
          <mds-button
            variation="icon-only"
            size="small"
            icon="angle-double-right"
            @click.stop="handlePopover(menuItem)"
          />
        </template>
      </mds-list-group-item>
    </mds-list-group>
    <SubMenu
      v-if="subMenuItem.items.length > 0"
      :subMenu="subMenuItem.items"
      :position="['right-center']"
      :triggered-by="subMenuItem.id"
      :visible="subMenuVisibility"
      @popOverClose="subMenuVisible = false"
    />
  </mds-popover>
</template>

<script>
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsPopover from '@mds/popover';
import { MdsButton } from '@mds/button';

export default {
  name: 'SubMenu',
  props: {
    triggeredBy: {
      type: String,
      default: '',
    },
    subMenu: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
    },
    position: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    MdsListGroup,
    MdsListGroupItem,
    MdsPopover,
    MdsButton,
  },
  data() {
    return {
      isOpen: false,
      subMenuVisible: false,
      subMenuItem: {
        items: [],
      },
    };
  },
  computed: {
    subMenuVisibility() {
      return this.subMenuVisible && this.visible;
    },
  },
  watch: {
    visible() {
      this.setPopoverPosition();
    }
  },
  mounted() {
    window.addEventListener('scroll', this.setPopoverPosition);
  },
  destroyed() {
    window.removeEventListener('scroll', this.setPopoverPosition);
  },
  methods: {
    handleItemClick(item) {
      const routePath = item?.link;
      if (this.$route?.path !== routePath && routePath !== undefined && routePath !== '#') {
        this.$router.push({ path: routePath }).catch();
      }
      this.$emit('popOverClose');
      this.$emit('onClickTab');
    },
    handlePopover(item) {
      this.subMenuVisible = !this.subMenuVisible;
      if (item) {
        this.subMenuItem = {
          id: `sub-menu-${item.id}`,
          items: item?.subMenu || [],
        };
      }
      this.setPopoverPosition();
    },
    handleClosePopover() {
      this.$emit('popOverClose');
    },
    setPopoverPosition() {
      setTimeout(() => {        
        const popoverEl = document.getElementsByClassName('mds-popover');
        if (popoverEl && popoverEl?.length > 0) {
          popoverEl.forEach((el) => {
            el.style.top = window.scrollY > 36 ? '100px' : '132px';
          });
        }
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
.mds-popover {
  background: $mds-background-color-light-gray;
  z-index: $mds-z-index-sticky;
  border-radius: 0px;
  border-left: 0px;
  position: fixed;
  box-shadow: none;
}
.sub-menu {
  &-item {
    display: block;
    padding: $mds-space-half-x 0;
  }
  ::v-deep {
    .mds-list-group__link {
      &:hover,
      &:active,
      &:focus {
        color: $mds-color-red-50;
      }
    }
    .mds-list-group__item-text {
      @include mds-level-6-heading();
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
