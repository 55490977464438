<template>
  <mds-layout-grid class="research-section">
    <mds-row align-horizontal="center">
      <mds-col align-vertical="center" :cols="12">
        <div v-if="loading" class="research-section-loader">
          <mds-loader />
        </div>
        <div v-else-if="filteredResearchPapers.length > 0">
          <mds-row 
            v-for="item in filteredResearchPapers" 
            class="research-section-item" 
            align-horizontal="center" 
            :key="item.id"
          >
            <mds-col :cols="12">
              <ResearchItem
                :itemid="item.id"
                :title="item.title"
                :description="item.description"
                :publishDate="item.publishDate"
                :researchPaperUrl="item.researchPaperUrl"
              />
            </mds-col>
          </mds-row>
          <mds-row class="research-section-view-all-link">
            <mds-col class="view-all-link" :cols="12">
              <div class="dv-view-all-link">
                <router-link class="view-all-table-link" :to="researchLink.researchList">View All Research > </router-link>
              </div>
            </mds-col>
          </mds-row>
        </div>
        <div v-else class="research-section-empty-state">
          <mds-empty-state title="No Records Found" message="" />
        </div>
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import mciaLinksData from '@/data/constants.json';
import ResearchItem from '@/components/Research/ResearchItem.vue';
import { getResearchList } from '@/services/researchService.js';
import MdsEmptyState from '@mds/empty-state';
import MdsLoader from '@mds/loader';

export default {
  name: 'Research',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    ResearchItem,
    MdsEmptyState,
    MdsLoader
  },
  data() {
    return {
      researchItems: [],
      colSizes: [11, 5, 5, 3],
      researchLink: mciaLinksData?.links,
      loading: false,
    };
  },
  created() {
    this.getResearchList();
  },
  computed: {
    filteredResearchPapers() {
      const result = this.researchItems.slice(0, 3);
      return result;
    },
  },
  methods: {
    updateColSizes(arr) {
      if (arr.length >= 4) this.colSizes = [11, 5, 5, 4];
      else if (arr.length === 3) this.colSizes = [11, 4, 4, 3];
      else if (arr.length <= 2) this.colSizes = [11, 5, 5, 3];
    },
    getResearchList() {
      this.loading = true;
      getResearchList()
        .then(async (resp) => {
          if (resp?.length > 0) {
            this.researchItems = resp?.slice().sort((a, b) => {
              return new Date(b.publishDate) - new Date(a.publishDate);
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.research-section-item {
  height: 178px;
  border-bottom: $mds-border-separator-on-light;
}
.research-section-view-all-link {
  height: 50px;
}
img {
  display: block;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}

.research-section {
  border-radius: $mds-border-radius-panel;
  border: $mds-border-separator-on-light;
  height: 586px;
  overflow: hidden;
  margin-bottom: $mds-space-4-x;
}

.dv-view-all-link {
  text-align: center;
  background-color: $mds-background-color-light-gray;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-all-table-link {
  @include mds-link($in-table: true);
  @include mds-body-text-m($bold: true);
}

.research-section-loader,
.research-section-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}
</style>
