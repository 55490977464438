import Vue from "vue";
import App from "./App.vue";
import VueGtm from '@gtm-support/vue2-gtm';
import router from "./router";
//import VueGtag from "vue-gtag";
import VueCarousel from 'vue-carousel';
import store from './store';
import vueDebounce from 'vue-debounce';
import VueCompositionAPI from '@vue/composition-api';

Vue.config.productionTip = false;
Vue.use(VueCarousel);
const contactType = Vue.observable({ contactType: "common" })
Object.defineProperty(Vue.prototype, '$contactType', {
  get () {
    return contactType.contactType
  },
  set (value) {
    contactType.contactType = value
  }
})
if(process.env.VUE_APP_GOOGLE_ANALYTICS_GTM_ID){
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: true // Whether or not call trackView in Vue.nextTick
  });
}

Vue.use(vueDebounce, {
  defaultTime: '300ms',
});

Vue.use(VueCompositionAPI);

new Vue({
  router,
  store,
  data() {
    return {
      toggle: false
    }
  },
  render: (h) => h(App),
}).$mount("#app");