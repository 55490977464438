<template>
  <div id="about-us" class="mcia-outer-container">
    <div class="mcia-inner-container">
      <mds-layout-grid class="dv-about-section">
        <mds-row class="dv-about-section-row">
          <mds-col>
            <div class="dv-about-section-row-title">
              <span v-html="title" class="dv-about-section-row-title-label"></span>
            </div>
            <div v-html="description" class="dv-about-section-row-desc"></div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: 'AboutUs',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dv-about-section {
  &-row {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $mds-text-color-primary-on-dark;
    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-title-label {
      @include mds-level-2-heading($bold: true);
      border-bottom: $mds-space-half-x solid;
      padding-bottom: $mds-space-1-x;
      text-align: center;
      margin-bottom: $mds-space-2-x;
    }
    &-desc {
      @include mds-body-text-l();
      text-align: center;
      width: var(--text-width);
      margin: 0 auto;
    }
  }
}
.mcia-outer-container {
  background-image: url(../../assets/AboutUs.png);
  min-height: 250px;
  display: flex;
  align-items: center;
}
</style>
