<template>
  <div v-if="showAlert" class="alert__container">
    <mds-alert
      class="alert__layout"
      :title="alert.title"
      :variation="alert.variation || 'error'"
      :size="alert.size"
      :tinted="alert.tinted"
      @mds-alert-dismissed="() => dismiss()"
    >
      {{ alert.message }}
    </mds-alert>
  </div>
</template>

<script lang="js">
import alertEvent from '@/services/alertEvent';
import MdsAlert from '@mds/alert';

export default {
  name: 'AppAlert',
  components:{
    MdsAlert
  },
  data() {
    return {
      showAlert: false,
      alert: {
        title: '',
        message: '',
      },
      timer: 0,
    };
  },
  props: ['alertData'],
  watch: {
    alertData: {
      immediate: true,
      handler(value) {
        if (value) {
          this.handleAlert(value);
        }
      },
    },
  },
  created() {
    alertEvent.$on('alert', this.handleAlert);
  },
  methods: {
    startDismissDelay() {
      this.stopDismissDelay();
      this.timer = setTimeout(() => {
        this.dismiss();
      }, this.alert?.delay || 5000);
    },
    stopDismissDelay() {
      clearTimeout(this.timer);
      this.timer = 0;
    },
    dismiss() {
      this.showAlert = false;
      this.stopDismissDelay();
      this.$emit('dismissed');
    },
    handleAlert(value) {
      this.showAlert = true;
      this.alert = value;
      if (!this.alert.disableAutoDismiss) {
        this.startDismissDelay();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  &__container {
    display: flex;
    justify-content: center;
  }
  &__layout {
    position: fixed;
    width: 300px;
    top: 10%;
    z-index: $mds-z-index-top-of-the-world;
  }
}
@media (min-width: $mds-breakpoint-s) {
  .alert {
    &__layout {
      width: 400px;
    }
  }
}
</style>

<style lang="scss" module>
:global(.alert) {
  .mds-alert {
    z-index: $mds-z-index-modal-overlay;
  }
}
</style>
