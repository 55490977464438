const isAdminUser = (state) => {
  return state.isAdminUser;
};
const userRoles = (state) => {
  return state.userRoles;
};
const userName = (state) => {
  return state.userName;
};
export default {
  isAdminUser,
  userRoles,
  userName,
};
