<template>
  <div class="mcia-outer-container">
    <Banner
      :title="homeBannerData.title"
      :description="homeBannerData.description"
      :imageName="homeBannerData.backgroundImage"
      :height="homeBannerData.bannerHeight"
      :responsive="true"
    />
    <div class="mcia-inner-container">
      <mds-layout-grid>
        <mds-row align-horizontal="center" class="mcia-home-section-main">
          <mds-col align-horizontal="center" :cols="12" :cols-at-l="8">
            <div class="dv-mcia-section-title">
              <span class="section-title">Products</span>
            </div>
            <Products />
          </mds-col>
          <mds-col align-horizontal="center" :cols="12" :cols-at-l="4">
            <div class="dv-mcia-section-title">
              <mds-tabs responsive
                variation="site-navigation"
                class="dv-mcia-nav-menu"
                :size="tabSize"
              >
                <mds-tabs-item
                  v-for="tab in researchNewsflashconfig"
                  class="ft-home-tab-outer"
                  :id="`ft-tabs-section__item-${tab.id}`"
                  :key="tab.id"
                  :text="tab.label"
                  :title="tab.label"
                  :active="activeTabId === tab.id"
                  @mds-tabs-item-active="() => onSelectedTab(tab)"
                >
                </mds-tabs-item>
              </mds-tabs>
              <Research
                v-show="selectedTab.component === 'research'"
              />
              <NewsFlashHomeList
                v-show="selectedTab.component === 'newsflash'"
              />
            </div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
    <AboutUs :title="aboutUsData.title" :description="aboutUsData.description" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MdsTabs, MdsTabsItem } from '@mds/tabs';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import AboutUs from '@/components/AboutUs/AboutUs.vue';
import Products from '@/components/Product/Products.vue';
import Research from '@/components/Research/Research.vue';
import NewsFlashHomeList from '@/components/NewsFlash/NewsFlashHomeList.vue';
import data from '@/data/constants.json';
import Banner from '@/components/Common/Banner.vue';
import tabConfig from '@/data/tabConfig.json';
import BannerData from '@/data/banner.json';

export default {
  name: 'Home',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    AboutUs,
    Products,
    Research,
    NewsFlashHomeList,
    Banner,
    MdsTabs,
    MdsTabsItem,
  },
  mounted() {
    this.setShowSignInButton(true);
  },
  beforeDestroy() {
    this.setShowSignInButton(false);
  },
  data() {
    return {
      activeTabId: 'ft-research',
      tabSize: 'large',
      tabAlignment: 'center',
      researchNewsflashconfig: tabConfig,
      selectedTab: tabConfig[0],
      aboutUsData: data?.aboutUs,
      homeBannerData: BannerData.homeBanner
    };
  },
  methods: {
    ...mapActions({
      setShowSignInButton: 'signIn/setSignInVisibility',
    }),
    onSelectedTab(tab) {
      this.activeTabId = tab.id;
      this.selectedTab = tab;
    }
  }
};
</script>

<style lang="scss" scoped>
.section-title {
  @include mds-level-2-heading($bold: true);
  border-bottom: $mds-space-half-x solid $mds-color-red-50;
  color: $mds-text-color-primary;
  text-align: left;
}
.dv-mcia-section-title {
  margin: $mds-space-2-x 0;
  margin-bottom: $mds-space-3-x;
}
.mcia-home-section-main {
  margin-top: $mds-space-5-x;
  margin-bottom: $mds-space-5-x;
}
.dv-mcia-nav-menu {
  padding-bottom: $mds-space-1-x;
}
.ft-home-tab-outer {
  margin-right: calc(#{$mds-space-6-x} + #{$mds-space-quarter-x});
  ::v-deep .mds-button {
    font-size: 28px !important;
  }
}
.mds-tabs__item--active {
  ::v-deep .mds-button {
    font-size: 28px !important;
    font-weight: 700 !important;
  }
}
</style>
