<template>
  <div>
    <mds-card size="small" :label="formattedDate" :title="title" :caption="description">
    </mds-card>
    <mds-button-container rightAligned class="mcia-research-item-card-action-btn">
      <mds-button
        rightAligned
        variation="secondary"
        icon="download"
        type="button"
        @click="downloadButtonClick(title, researchPaperUrl)"
      >
        Download
      </mds-button>
    </mds-button-container>
    <mds-modal v-model="toggle" aria-label="Default Modal" :width="'600px'">
      <mds-section 
        bold 
        border="primary" 
        borderPosition="bottom" 
        title="Sign up to receive your copy:"
      >
        <template v-slot:mds-section-actions>
          <mds-button-container right-aligned>
            <mds-button variation="icon-only" icon="remove" @click="toggle = !toggle">Close</mds-button>
          </mds-button-container>
        </template>
        <DownloadResearchForm :researchPaperTitle="title" :researchPaperUrl="researchPaperUrl" @exit="closeModal" />
      </mds-section>
    </mds-modal>
  </div>
</template>

<script>
import moment from 'moment';
import MdsCard from '@mds/card';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';

export default {
  name: 'ResearchItem',
  components: {
    MdsCard,
    MdsModal,
    MdsSection,
    MdsButton,
    MdsButtonContainer,
    DownloadResearchForm: () => import('@/components/DownloadResearchForm.vue'),
  },
  data() {
    return {
      toggle: this.toggle,
    };
  },
  computed: {
    formattedDate() {
      return moment(this.publishDate).format('MMM DD, YYYY');
    },
  },
  props: {
    itemid: {
      type: String,
      default: '',
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: true,
    },
    publishDate: {
      type: String,
      default: '',
      required: false,
    },
    researchPaperUrl: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    closeModal: function () {
      this.toggle = !this.toggle;
    },
    downloadButtonClick: function () {
      this.toggle = !this.toggle;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@mds/constants';
@import '@mds/fonts';
@import '@mds/typography';
@import '@mds/link';

.mcia-research-item-card-action-btn {
  padding-right: $mds-space-1-x;
  padding-top: $mds-space-half-x !important;
}
.mds-card {
  border-width: 0px;
  margin-top: $mds-space-1-x;
  width: 100%;
  height: 118px;
  border-radius: 0;
}

.remove-underline {
  text-decoration: none;
}
.mds-card {
  ::v-deep {
    .mds-card__action {
      text-align: right;
      margin-bottom: $mds-space-1-x !important;
    }
    .mds-card__caption {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      @include mds-body-text-m();
      padding-bottom: $mds-space-half-x;
      margin-bottom: $mds-space-1-x !important;
    }
    .mds-card__title {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      padding-bottom: $mds-space-half-x;
      margin-bottom: $mds-space-1-x !important;
      @include mds-body-text-m($bold: true);
    }
  }
}
</style>
