import axios from "axios";

const VUE_APP_TOP_HAT_API_URL = process.env.VUE_APP_TOP_HAT_API_URL;
const VUE_APP_TOP_HAT_ENV = process.env.VUE_APP_TOP_HAT_ENV;
const VUE_APP_TOP_HAT_API_KEY = process.env.VUE_APP_TOP_HAT_API_KEY;
const VUE_APP_TOP_HAT_ACCESS_TOKEN = process.env.VUE_APP_TOP_HAT_ACCESS_TOKEN;

export async function fetchTopHatData() {
  const url = `${VUE_APP_TOP_HAT_API_URL}/${VUE_APP_TOP_HAT_API_KEY}?environment=${VUE_APP_TOP_HAT_ENV}`;
  const body = {
    query:
      'query getTopHatData {\n nav(uid: "blt45524a4b42327baa") {\n title\n nav_items {\n ... on NavNavItemsLogo {\n __typename\n logo {\n large_viewport_title_justify\n url\n }\n }\n ... on NavNavItemsDrawerOpener {\n __typename\n drawer_opener {\n large_viewport_title_justify\n drawer_columns {\n ... on NavNavItemsDrawerOpenerBlockDrawerColumnsLink {\n __typename\n link {\n link {\n title\n href\n }\n }\n }\n ... on NavNavItemsDrawerOpenerBlockDrawerColumnsCustomList {\n __typename\n custom_list {\n column_name\n links {\n link {\n href\n title\n }\n }\n }\n }\n ... on NavNavItemsDrawerOpenerBlockDrawerColumnsTaxonomyList {\n __typename\n taxonomy_list {\n column_name_override\n listConnection {\n totalCount\n edges {\n node {\n ... on List {\n title\n global_field {\n morningstar_taxonomy {\n ... on TaxonomyMorningstarTaxonomyFamilyBrands {\n __typename\n family_brands {\n family_brandsConnection {\n edges {\n node {\n ... on FamilyBrand {\n title\n url\n }\n }\n }\n }\n }\n }\n ... on TaxonomyMorningstarTaxonomyRoles {\n __typename\n roles {\n rolesConnection {\n edges {\n node {\n ... on Audience {\n title\n url\n }\n }\n }\n }\n }\n }\n ... on TaxonomyMorningstarTaxonomyProducts {\n __typename\n products {\n productsConnection {\n edges {\n node {\n ... on Product {\n title\n url\n }\n }\n }\n }\n }\n }\n }\n }\n }\n }\n }\n }\n }\n }\n }\n drawer_opener_text\n }\n }\n ... on NavNavItemsLink {\n __typename\n link {\n large_viewport_title_justify\n link {\n href\n title\n }\n }\n }\n ... on NavNavItemsStaticText {\n __typename\n static_text {\n large_viewport_title_justify\n single_line\n }\n }\n }\n }\n}',
    variables: {},
  };
  const headers = {
    access_token: VUE_APP_TOP_HAT_ACCESS_TOKEN,
  };
  const response = await axios.post(url, body, { headers });
  return response.data?.data;
}
