const mutations = {
  SET_ADMIN_USER(state, payload) {
    state.isAdminUser = payload;
  },
  RESET_USER(state) {
    state.isAdminUser = false;
  },
  SET_USER_ROLES(state, payload) {
    state.userRoles = payload;
  },
  SET_USER_NAME(state, payload) {
    state.userName = payload;
  },
};

export default mutations;
