const actions = () => {
  return {
    setActiveSignInLink: {
      handler({ commit }, value) {
        commit('SET_ACTIVE_SIGN_IN_LINK', value);
      },
    },
    setSignInVisibility: {
      handler({ commit }, value) {
        commit('SET_SIGN_IN_VISIBILITY', value);
      },
    },
    setMenuVisibility: {
      handler({ commit }, value) {
        commit('SET_MENU_VISIBILITY', value);
      },
    },
    setSelectedProducts: {
      handler({ commit }, value) {
        commit('SET_SELECTED_PRODUCTS', value);
      },
    },
    setGetStartedVisibility: {
      handler({ commit }, value) {
        commit('SET_GET_STARTED_VISIBILITY', value);
      },
    }
  };
};

export default actions;
