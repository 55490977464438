<template>
  <div>
    <div
      class="mcia-outer-container"
      v-bind:style="{ backgroundImage: `url(${imagePath})`, height: height }">
      <div class="mcia-inner-container">
        <mds-layout-grid class="hero-banner">
          <mds-row
            v-bind:style="{ height: rowHeight }"
            class="hero-banner-row"
            align-vertical="center"
            align-horiontal="center"
          >
            <mds-col :cols="11" v-bind:style="{ margin: margin }">
              <div class="hero-banner-row-title" :class="{'responsive__hero-banner-row-title': responsive}">
                {{ title }}
              </div>
              <div
                v-if="description != ''"
                class="hero-banner-row-desc" 
                :class="{'responsive__hero-banner-row-desc': responsive}"
                v-html="description"
              >
              </div>
              <div v-if="showButton">
                <router-link class="hero-banner-row-link-style" :to="mciaLinksData.analytics">
                  <mds-button class="button-css" variation="primary" size="large">Know More</mds-button>
                </router-link>
              </div>
            </mds-col>
          </mds-row>
          <mds-row>
            <mds-col :cols="12" v-if="showDealXBannerIcon">
              <div class="hero-banner-row-poweredby">
                {{ bannerData.dealXBanner.poweredBy }}
                <img
                  class="hero-banner-row-img"
                  :src="require('../../assets/analytics-dealx-logo-white.png')"
                />
              </div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import AnalyticsData from "@/data/creditAnalytics.json";
import mciaData from '@/data/constants.json';
import { MdsButton } from '@mds/button';
import BannerData from "@/data/banner.json";

export default {
  name: 'Banner',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton
  },
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: false,
    },
    showButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    imageName: {
      type: String,
      default: '',
      required: true,
    },
    height: {
      type: String,
      default: '',
      required: true,
    },
    responsive: {
      type: Boolean,
      default: false,
      required: false,
    },
    showDealXBannerIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
    rowHeight: {
      type: String,
      default: '100%',
      required: false,
    },
    margin: {
      type: String,
      default: '',
      required: false,
    }
  },
  computed: {
    imagePath() {
      return require('../../assets/' + this.imageName);
    }
  },
  data() {
    return {
      analyticsData: AnalyticsData,
      mciaLinksData: mciaData?.links,
      bannerData: BannerData,
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mcia-banner-section {
  &-title {
    @include mds-level-1-heading($bold: true);
    color: $mds-color-white;
    text-align: left;
  }
}
.hero-banner {
  height: 100%;
  width: 100%;
  &-row {
    height: 100%;
    text-align: left;
    &-title {
      @include mds-level-1-heading($bold: true);
      font-weight: 700;
      color: $mds-color-white;
      text-align: left;
    }
    &-desc {
      @include mds-body-text-l();
      font-weight: 300;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.01em;
      color: $mds-color-white;
      text-align: left;
      padding-top: $mds-space-1-x;
    }
    &-poweredby {
      @include mds-body-text-l();
      letter-spacing: 0.01em;
      color: $mds-color-white;
      float: right;
      display: flex;
      align-items: center;
    }
    &-img{
      padding-left: $mds-space-1-x;
    }
    &-link-style {
      @include mds-link($underline: false);
    }
  }
}

.mcia-banner-section {
  &-title {
    @include mds-level-1-heading($bold: true);
    color: $mds-color-white;
    text-align: left;
  }
}
.button-css {
  background-color: $mds-background-color-white;
  color: $mds-color-black;
  margin-top: $mds-space-2-and-a-half-x;
}
.mcia-outer-container {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border: $mds-border-container;
  .mcia-inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}

@media (max-width: $mds-breakpoint-s) {
  .responsive__hero-banner-row-title {
    font-size: 26px!important;
  }
  .responsive__hero-banner-row-desc {
    font-size: 14px!important;
  }
}
</style>
