import Vue from 'vue';
import Vuex from 'vuex';

import signIn from './signIn';
import tophat from './tophat';
import user from './user';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    clearState: {
      handler({ commit }) {
        commit('signIn/SET_ACTIVE_SIGN_IN_LINK', '');
        commit('signIn/SET_SIGN_IN_VISIBILITY','');
        commit('signIn/SET_SELECTED_PRODUCTS','');
        commit('signIn/SET_MENU_VISIBILITY', true);
        commit('tophat/GET_TOP_HAT_DATA', {});
        commit('user/RESET_USER');
      },
    },
  },
  modules: {
    signIn,
    tophat,
    user,
  },
});
