import { fetchTopHatData } from "../../apis/tophat";

const actions = () => {
  return {
    getTopHatData: {
      async handler({ commit }) {
        commit("GET_TOP_HAT_DATA", await fetchTopHatData());
      },
    },
  };
};

export default actions;
