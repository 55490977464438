const activeSignInLink = (state) => {
  return state.activeSignInLink;
};
const showSignInButton = (state) => {
  return state.showSignInButton;
};
const showGetStartedButton = (state) => {
  return state.showGetStartedButton;
};
const showMenu = (state) => {
  return state.showMenu;
};
const selectedProducts = (state) => {
  return state.selectedProducts;
};
export default { activeSignInLink, showSignInButton, selectedProducts, showMenu, showGetStartedButton };
