<template>
  <div class="loader">
    <div class="loader__overlay" />
    <mds-loader :class="colorClass" :size="size" />
  </div>
</template>

<script>
import MdsLoader from '@mds/loader'

export default {
  name: 'LockScreenLoader',
  components:{
    MdsLoader
  },
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    colorClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.loader{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  &__overlay{
    background: $mds-color-white;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .green_loader .mds-loader__item::after{
    background: $mds-feedback-color-success;
  }
}
</style>